import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import background from '../images/home-bg.jpg'
import '../styles/not-found.css'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found - " />
    <div className="page-wrapper" style={{ backgroundImage: `url(${background})` }}>
      <h3>(404 Not Found)</h3>
      <h1>A Little Lost?</h1>
      <h4>Sometimes being lost is a good thing. It's liberating.</h4>
      <p>Just know you can always go</p>
      <a href="/" className="home-btn">
        <i className="fas fa-house-damage"></i>
        Home
      </a>
    </div>
  </Layout>
)

export default NotFoundPage
